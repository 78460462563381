var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{attrs:{"show":_vm.alert.show,"variant":_vm.alert.variant,"fade":""}},[_c('span',{staticClass:"text-light-75 font-weight-bold d-block font-size-lg"},[_vm._v(_vm._s(_vm.alert.text))])]),_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(_vm._s(_vm.$t("USER.USERS")))]),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(_vm._s(_vm.$tc("USER.SHOW_COUNT", _vm.paginatedUsers.length, { total: _vm.users.length, })))])]),_c('div',{staticClass:"card-toolbar"},[_c('router-link',{attrs:{"to":{
          name: 'user',
          params: this.$route.params.id
            ? { sub: 'new', brand_id: this.$route.params.id }
            : { sub: 'new' },
        }}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.$t("USER.NEW")))])],1)],1)]),_c('div',{staticClass:"card-body py-0"},[(_vm.users.length)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-head-custom table-vertical-center",attrs:{"id":"kt_advance_table_widget_4"}},[_c('thead',[_c('tr',{staticClass:"text-left"},[_c('th',{staticClass:"pl-0",staticStyle:{"min-width":"120px"}},[_vm._v(" "+_vm._s(_vm.$t("USER.EMAIL"))+" ")]),_c('th',{staticStyle:{"min-width":"80px"}},[_vm._v(_vm._s(_vm.$t("USER.TITLE")))]),_c('th',{staticStyle:{"min-width":"110px"}},[_vm._v(_vm._s(_vm.$t("USER.FIRST_NAME")))]),_c('th',{staticStyle:{"min-width":"110px"}},[_vm._v(_vm._s(_vm.$t("USER.LAST_NAME")))]),_c('th',{staticStyle:{"min-width":"120px"}},[_vm._v(_vm._s(_vm.$t("USER.FUNCTION")))]),_c('th',{staticClass:"pr-0 text-right",staticStyle:{"min-width":"160px"}},[_vm._v(" "+_vm._s(_vm.$t("ACTIONS"))+" ")])])]),_c('tbody',[_vm._l((_vm.paginatedUsers),function(user){return [_c('tr',{key:user.sub},[_c('td',{staticClass:"pl-0"},[_c('router-link',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary font-size-lg",attrs:{"to":{ name: 'user', params: { sub: user.sub } }}},[_vm._v(_vm._s(user.email)+" ")])],1),_c('td',[_c('span',{staticClass:"text-dark-75 font-weight-bold d-block font-size-lg"},[_vm._v(_vm._s(user.title))])]),_c('td',[_c('span',{staticClass:"text-dark-75 font-weight-bold d-block font-size-lg"},[_vm._v(_vm._s(user.first_name))])]),_c('td',[_c('span',{staticClass:"text-dark-75 font-weight-bold d-block font-size-lg"},[_vm._v(_vm._s(user.last_name))])]),_c('td',[_c('span',{staticClass:"text-dark-75 font-weight-bold d-block font-size-lg"},[_vm._v(_vm._s(user.function))])]),_c('td',{staticClass:"pr-0 text-right"},[_c('router-link',{staticClass:"btn btn-icon btn-light btn-hover-primary btn-sm mx-3",attrs:{"to":{
                    name: 'user',
                    params: { sub: user.sub, edit: true },
                  }}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Write.svg","title":_vm.$t('EDIT')}})],1)]),(_vm.currentUser.sub != user.sub)?_c('a',{staticClass:"btn btn-icon btn-light btn-hover-primary btn-sm",on:{"click":function($event){return _vm.confirmDelete(user.sub)}}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Trash.svg","title":_vm.$t('DELETE')}})],1)]):_c('a',{staticClass:"btn btn-icon btn-light btn-sm"},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Trash.svg","title":_vm.$t('DELETE')}})],1)])],1)])]})],2)]),(_vm.users.length > _vm.pagination.per_page)?_c('b-pagination',{staticClass:"pt-5",attrs:{"total-rows":_vm.users.length,"per-page":_vm.pagination.per_page,"align":"right"},model:{value:(_vm.pagination.current_page),callback:function ($$v) {_vm.$set(_vm.pagination, "current_page", $$v)},expression:"pagination.current_page"}}):_vm._e()],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }