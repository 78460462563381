<template>
    <div>
        <b-alert :show="alert.show" :variant="alert.variant" fade>
      <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
        alert.text
      }}</span>
        </b-alert>
        <!--begin::Advance Table Widget 10-->
        <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("USER.USERS")
          }}</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
            $tc("USER.SHOW_COUNT", paginatedUsers.length, {
              total: users.length,
            })
          }}</span>
                </h3>
                <div class="card-toolbar">
                    <router-link
                            :to="{
              name: 'user',
              params: this.$route.params.id
                ? { sub: 'new', brand_id: this.$route.params.id }
                : { sub: 'new' },
            }"
                    >
                        <b-button variant="primary">{{ $t("USER.NEW") }}</b-button>
                    </router-link>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="users.length">
                    <table
                            class="table table-head-custom table-vertical-center"
                            id="kt_advance_table_widget_4"
                    >
                        <thead>
                        <tr class="text-left">
                            <th class="pl-0" style="min-width: 120px">
                                {{ $t("USER.EMAIL") }}
                            </th>
                            <th style="min-width: 80px">{{ $t("USER.TITLE") }}</th>
                            <th style="min-width: 110px">{{ $t("USER.FIRST_NAME") }}</th>
                            <th style="min-width: 110px">{{ $t("USER.LAST_NAME") }}</th>
                            <th style="min-width: 120px">{{ $t("USER.FUNCTION") }}</th>
                            <th class="pr-0 text-right" style="min-width: 160px">
                                {{ $t("ACTIONS") }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="user in paginatedUsers">
                            <tr v-bind:key="user.sub">
                                <td class="pl-0">
                                    <router-link
                                            :to="{ name: 'user', params: { sub: user.sub } }"
                                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                    >{{ user.email }}
                                    </router-link
                                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ user.title }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ user.first_name }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ user.last_name }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ user.function }}</span
                    >
                                </td>
                                <td class="pr-0 text-right">
                                    <router-link
                                            :to="{
                        name: 'user',
                        params: { sub: user.sub, edit: true },
                      }"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                        <inline-svg
                                src="media/svg/icons/Communication/Write.svg"
                                :title="$t('EDIT')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </router-link>
                                    <a
                                            v-if="currentUser.sub != user.sub"
                                            v-on:click="confirmDelete(user.sub)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                                :title="$t('DELETE')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </a>
                                    <a v-else class="btn btn-icon btn-light btn-sm">
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                                :title="$t('DELETE')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </a>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                    <b-pagination
                            v-if="users.length > pagination.per_page"
                            class="pt-5"
                            v-model="pagination.current_page"
                            :total-rows="users.length"
                            :per-page="pagination.per_page"
                            align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Advance Table Widget 10-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import Swal from "sweetalert2";
    import {mapGetters} from "vuex";

    export default {
        name: "users",
        data() {
            return {
                users: [],
                alert: {
                    show: false,
                    message: "",
                    variant: "",
                },
                pagination: {
                    current_page: 1,
                    per_page: 20,
                },
            };
        },
        mounted() {
            if (!this.$route.params.id) {
                this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("USER.USERS")}]);
            }
            ApiService.get(
                this.$route.params.id
                    ? "brand/" + this.$route.params.id + "/users"
                    : "users"
            )
                .then(({data}) => {
                    this.users = data;
                })
                .catch(() => {
                    //Just keep it empty
                });
        },
        methods: {
            confirmDelete: function (sub) {
                const user_index = this.users.findIndex((user) => user.sub === sub);
                const user = this.users.find((user) => user.sub === sub);
                Swal.fire({
                    title: this.$t("USER.DELETE_TITLE", {name: user.email}),
                    text: this.$t("USER.DELETE_TEXT"),
                    icon: "error",
                    showCancelButton: true,
                    cancelButtonText: this.$t("CANCEL"),
                    confirmButtonText: this.$t("DELETE"),
                    buttonsStyling: false,
                    customClass: {
                        cancelButton: "btn btn-secondary",
                        confirmButton: "btn btn-danger",
                    },
                    heightAuto: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApiService.delete("user/" + sub)
                            .then(() => {
                                this.users.splice(user_index, 1);
                                this.showAlert(
                                    this.$t("USER.DELETE_SUCCESS", {name: user.email}),
                                    "success"
                                );
                            })
                            .catch(() => {
                                this.showAlert(
                                    this.$t("USER.DELETE_FAILURE", {name: user.email}),
                                    "danger"
                                );
                            });
                    }
                });
            },
            showAlert(text, variant) {
                window.scrollTo(0, 0);
                this.alert.text = text;
                this.alert.variant = variant;
                this.alert.show = true;
                setTimeout(() => (this.alert.show = false), 5000);
            },
        },
        computed: {
            ...mapGetters(["currentUser"]),
            paginatedUsers() {
                const start =
                    (this.pagination.current_page - 1) * this.pagination.per_page,
                    end = start + this.pagination.per_page;
                return this.users.slice(start, end);
            },
        },
    };
</script>
