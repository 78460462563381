<template>
    <div>
        <b-alert :show="not_found" variant="danger" fade>
            <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
                $t("BRAND.NOT_FOUND")
            }}</span>
        </b-alert>
        <!--begin::Card-->
        <div v-if="!not_found" class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3
                        v-if="$route.params.id"
                        class="card-label font-weight-bolder text-dark"
                    >
                        {{
                            edit_mode
                                ? add_new
                                    ? $t("BRAND.NEW")
                                    : $t("BRAND.EDIT")
                                : $t("BRAND.VIEW")
                        }}
                    </h3>
                    <h3 v-else class="card-label font-weight-bolder text-dark">
                        {{ edit_mode ? $t("ABOUT.EDIT") : $t("ABOUT.ABOUT") }}
                    </h3>
                    <span
                        class="text-muted font-weight-bold font-size-sm mt-1"
                        >{{ brand.name }}</span
                    >
                </div>
                <div v-if="edit_mode" class="card-toolbar">
                    <a
                        v-on:click="save()"
                        class="btn btn-primary px-15 mr-2"
                        id="brand_save"
                        >{{ $t("SAVE") }}
                    </a>
                    <a v-on:click="cancel()" class="btn btn-secondary"
                        >{{ $t("CANCEL") }}
                    </a>
                </div>
                <div v-else class="card-toolbar">
                    <a v-on:click="edit()" class="btn btn-primary px-10"
                        >{{ $t("EDIT") }}
                    </a>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                    <form class="form" id="brand_form">
                        <fieldset id="edit_wrapper" :disabled="!edit_mode">
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("BRAND.LOGO") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <div
                                        class="image-input image-input-outline"
                                        id="kt_profile_avatar"
                                    >
                                        <div
                                            class="image-input-wrapper"
                                            :style="{
                                                backgroundImage: `url(${logo})`,
                                            }"
                                        ></div>
                                        <label
                                            v-if="edit_mode"
                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="change"
                                        >
                                            <i
                                                class="fa fa-pen icon-sm text-muted"
                                            ></i>
                                            <input
                                                type="file"
                                                name="profile_avatar"
                                                accept=".png, .jpg, .jpeg"
                                                @change="changedLogo($event)"
                                            />
                                        </label>
                                        <span
                                            v-if="edit_mode"
                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="remove"
                                            @click="clearLogo()"
                                        >
                                            <i
                                                class="ki ki-bold-close icon-xs text-muted"
                                            ></i>
                                        </span>
                                    </div>
                                    <span
                                        v-if="edit_mode"
                                        class="form-text text-muted"
                                        >{{ $t("BRAND.LOGO_FILETYPES") }}</span
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("BRAND.NAME") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="name"
                                        name="name"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="brand.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("BRAND.TAX_ID") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="tax_id"
                                        name="tax_id"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="brand.tax_id"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("BRAND.STREET") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="street"
                                        name="street"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="brand.address.street"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("BRAND.CITY") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="city"
                                        name="city"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="brand.address.city"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("BRAND.ZIP") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="zip"
                                        name="zip"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="brand.address.zip"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("BRAND.COUNTRY") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="country"
                                        name="country"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="brand.address.country"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group mb-0 row"
                                v-if="currentUser.admin"
                            >
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("BRAND.PRODUCT_PAGE") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <a
                                        class="btn text-dark"
                                        :class="
                                            brand.product_page
                                                ? 'btn-success'
                                                : 'btn-danger'
                                        "
                                        @click="onProductPageChange()"
                                    >
                                        {{
                                            brand.product_page
                                                ? "Aktiv"
                                                : "Nicht aktiv"
                                        }}
                                    </a>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <!--end::Body-->
            </form>
            <!--end::Form-->
        </div>
        <div v-if="$route.params.id && !add_new && !not_found" class="mt-5">
            <Users></Users>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import ApiService from "@/core/services/api.service"
import Users from "@/view/pages/Users"
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger"
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap"
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core"
import KTUtil from "@/assets/js/components/util"
import Vue from "vue"
import { mapGetters } from "vuex"

export default {
    name: "brand",
    data() {
        return {
            add_new: false,
            edit_mode: false,
            not_found: false,
            default_logo: "media/images/placeholder.png",
            current_logo: null,
            new_logo: {},
            original_brand: null,
            brand: {
                name: "",
                logo: "",
                tax_id: "",
                address: {
                    street: "",
                    city: "",
                    zip: "",
                    country: "",
                },
                product_page: false,
            },
        }
    },
    mounted() {
        if (this.$route.params.id == "new") {
            this.add_new = true
            this.edit_mode = true
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: this.$t("BRAND.BRANDS"), route: "/brands" },
                { title: this.$t("BRAND.NEW") },
            ])
        } else {
            ApiService.get(
                this.$route.params.id
                    ? "brand/" + this.$route.params.id
                    : "brand"
            )
                .then((data) => {
                    this.original_brand = data.data
                    this.brand = JSON.parse(JSON.stringify(this.original_brand))
                    this.current_logo = this.brand.logo
                    if (this.$route.params.id) {
                        this.$store.dispatch(SET_BREADCRUMB, [
                            {
                                title: this.$t("BRAND.BRANDS"),
                                route: "/brands",
                            },
                            { title: data.data.name },
                        ])
                    } else {
                        this.$store.dispatch(SET_BREADCRUMB, [
                            { title: this.$t("ABOUT.ABOUT") },
                        ])
                    }

                    if (this.$route.params.edit) {
                        this.edit_mode = true
                    }
                })
                .catch(() => {
                    this.not_found = true
                    this.$store.dispatch(SET_BREADCRUMB, [])
                })
        }

        this.fv = formValidation(KTUtil.getById("brand_form"), {
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("BRAND.NAME"),
                            }),
                        },
                    },
                },
                street: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("BRAND.STREET"),
                            }),
                        },
                    },
                },
                city: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("BRAND.CITY"),
                            }),
                        },
                    },
                },
                zip: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("BRAND.ZIP"),
                            }),
                        },
                    },
                },
                country: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("BRAND.COUNTRY"),
                            }),
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        })
    },
    methods: {
        edit() {
            this.edit_mode = true
        },
        cancel() {
            if (this.add_new) {
                this.$router.push({ name: "brands" })
            } else {
                this.brand = JSON.parse(JSON.stringify(this.original_brand))
                this.fv.resetForm(true)
                this.current_logo = this.brand.logo
                this.new_logo = {}
                this.edit_mode = false
            }
        },
        save() {
            this.fv.validate().then((status) => {
                if (status == "Valid") {
                    KTUtil.getById("brand_save").classList.add(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    )

                    if (this.add_new) {
                        ApiService.post("brand", this.brand)
                            .then((data) => {
                                this.brand.id = data.data.id
                            })
                            .then(() => this.saveLogo(this.brand.id))
                            .then(() => {
                                KTUtil.getById("brand_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                                this.$router.push({
                                    name: "brand",
                                    params: { id: this.brand.id },
                                })
                            })
                            .catch(() => {
                                KTUtil.getById("brand_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    } else {
                        this.saveLogo(
                            this.$route.params.id ? this.brand.id : null
                        )
                            .then(() =>
                                ApiService.put(
                                    this.$route.params.id
                                        ? "brand/" + this.brand.id
                                        : "brand",
                                    this.brand
                                )
                            )
                            .then((data) => {
                                KTUtil.getById("brand_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                                this.original_brand = data.data
                                this.cancel()
                            })
                            .catch(() => {
                                KTUtil.getById("brand_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    }
                }
            })
        },
        saveLogo(id) {
            if (this.new_logo.data) {
                return ApiService.put(
                    id ? "brand/" + id + "/logo" : "logo",
                    this.new_logo
                )
            } else if (!this.current_logo && this.brand.logo) {
                return ApiService.delete(id ? "brand/" + id + "/logo" : "logo")
            } else {
                return Promise.resolve(true)
            }
        },
        changedLogo(event) {
            const file = event.target.files[0]
            const reader = new FileReader()
            reader.onload = (event) => {
                this.current_logo = event.target.result
                this.new_logo.data = event.target.result.replace(
                    /^data:image\/\w+;base64,/,
                    ""
                )
                this.new_logo.mime_type = file.type
            }
            reader.readAsDataURL(file)
        },
        clearLogo() {
            this.current_logo = null
            this.new_logo = {}
        },
        onProductPageChange() {
            Vue.set(this.brand, "product_page", !this.brand.product_page)
        },
    },
    computed: {
        logo() {
            return this.current_logo == null
                ? this.default_logo
                : this.current_logo
        },
        ...mapGetters(["currentUser"]),
    },
    components: {
        Users,
    },
}
</script>
